import { NgModule } from '@angular/core';
import { SessionModule } from '../session';
import { UserModule } from '../user';
import { ApplicationsService } from './applications.service';
import { BookmarksApplication } from './bookmarks-application';
import { PostsApplication } from './posts-application';
import { HomeApplication } from './home-application';
import { CompetitorsApplication } from './competitors-application';
import { SettingsApplication } from './settings-application';
import { AnalyticsModule } from '@heydayai/microapp-angular-core';

@NgModule({
  providers: [
    ApplicationsService,
    BookmarksApplication,
    PostsApplication,
    HomeApplication,
    CompetitorsApplication,
    SettingsApplication,
  ],
  imports: [
    SessionModule,
    UserModule,
    AnalyticsModule,
  ],
})
export class ApplicationsModule {
}
