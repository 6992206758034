import { AfterViewInit, Component, HostBinding, OnDestroy } from '@angular/core';
import { BehaviorSubject, of, pairwise, Subject, takeUntil, delay, from } from 'rxjs';
import { Application } from './applications';
import { ApplicationsService } from './applications/applications.service';
import { MainMenuItem } from './components/main-menu';
import { HubMessagingService } from './messaging/hub-messaging.service';

@Component({
  selector: 'hub-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  private static readonly loadingOverlayFadeOutMs: number = 250;

  /** Current applications. */
  public applications: Application[];

  /** States if the main menu is visible or not. */
  public mainMenuVisible: boolean;

  /** Main menu items to render, corresponding to the current applications. */
  public mainMenuItems: MainMenuItem[];

  /** Current application on which we're actively selecting a specific version, otherwise null. */
  public applicationVersionSelection: Application | null;

  /** States if the Teammate menu is opened or not. */
  public teammateMenuOpened: boolean;

  /** States if no application are fully loaded at the moment or not. */
  public noApplicationLoaded$: BehaviorSubject<boolean>;

  /** States if the overlay is being fading out or not. */
  public noApplicationLoadedFadeOut: boolean;

  /** Listener reset for application loading mechanism. */
  public noApplicationLoadedUnsubscribe$: Subject<void>;

  private onDestroy: Subject<void> = new Subject();

  /** Class to notify any loading overlay that the app is now loaded. */
  @HostBinding('class.loaded')
  private hubLoaded: boolean;

  public constructor(
    private applicationsService: ApplicationsService,
    private messagingService: HubMessagingService,
  ) {
    this.hubLoaded = false;
    this.applications = [];
    this.mainMenuVisible = true;
    this.mainMenuItems = [];
    this.applicationVersionSelection = null;
    this.teammateMenuOpened = false;
    this.noApplicationLoaded$ = new BehaviorSubject<boolean>(true);
    this.noApplicationLoadedFadeOut = false;
    this.noApplicationLoadedUnsubscribe$ = new Subject<void>();

    this.noApplicationLoaded$.pipe(pairwise()).subscribe(([previousState, currentState]: boolean[]) => {
      // No app was loaded and now at least one is
      if (previousState && !currentState) {
        this.noApplicationLoadedFadeOut = true;

        of(0).pipe(
          takeUntil(this.noApplicationLoadedUnsubscribe$),
          delay(AppComponent.loadingOverlayFadeOutMs)
        ).subscribe((): void => {
          this.noApplicationLoadedFadeOut = false;
        });
      }
    });

    this.messagingService.listenForHubMessage();

    this.applicationsService.applications$
      .pipe(takeUntil(this.onDestroy))
      .subscribe((applications: Application[]): void => {
        // Resets listeners on application loading state.
        this.noApplicationLoadedUnsubscribe$.next();
        this.noApplicationLoadedFadeOut = false;

        this.updateApplications(applications);

        let oneOrMoreApplicationLoaded: boolean = false;

        applications.forEach((application: Application): void => {
          oneOrMoreApplicationLoaded ||= application.isLoaded();

          if (application.isActive()) {
            this.mainMenuVisible = !application.isFullscreen();
          }
        });

        this.noApplicationLoaded$.next(oneOrMoreApplicationLoaded);

        if (!oneOrMoreApplicationLoaded) {
          applications.forEach((application: Application): void => {
            application.onceLoaded().pipe(takeUntil(this.noApplicationLoadedUnsubscribe$)).subscribe((): void => {
              this.noApplicationLoaded$.next(false);
            })
          });
        }
    });
  }

  public ngAfterViewInit(): void {
    this.hubLoaded = true;
  }

  public ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();

    this.noApplicationLoadedUnsubscribe$.next();
    this.noApplicationLoadedUnsubscribe$.complete();
  }

  /** Updates the applications and the main menu items from the new applications list. */
  private updateApplications(applications: Application[]): void {
    this.applications = applications;
    this.updateMainMenuItems();
  }

  /** Creates the main menu items from the current applications list. */
  private updateMainMenuItems(): void {
    this.mainMenuItems = this.applications.filter(
      (application: Application): boolean => {
        return application.isPartOfMainMenu();
      }
    ).map((application: Application): MainMenuItem => {
      return {
        identifier: application.getIdentifier(),
        iconPath: application.getIconPath(),
        title: application.getTitle(),
        active: (application.isActive() && !this.applicationVersionSelection) || this.applicationVersionSelection?.getIdentifier() == application.getIdentifier(),
      };
    });
  }

  /** Switches the current application to the specified one. Switches also the version when specified. */
  public switchApplication(applicationKey: string, version: string | null = null): void {
    // Handle custom versions
    if (version) {
      const application: Application | undefined = this.applications.find((app: Application) => app.getIdentifier() == applicationKey);

      if (!application) {
        return;
      }

      const versions = application.getVersions();

      if (!versions.includes(version)) {
        application.registerVersion('custom', new URL(version));
        version = 'custom';
      }
    }

    this.applicationsService.switchApplication('main_menu', applicationKey, version);
    this.closeOverlayMenu();
  }

  /** Enables the version selection mode on the specified application. */
  public enableVersionSelection(applicationKey: string): void {
    const application: Application | undefined = this.applications.find((app: Application) => app.getIdentifier() == applicationKey);

    if (!application) {
      return;
    }

    this.teammateMenuOpened = false;
    this.applicationVersionSelection = application;
    this.updateMainMenuItems();
  }

  /** Opens the teammate menu. */
  public openTeammateMenu(): void {
    this.teammateMenuOpened = true;
  }

  /** Disables the version selection mode. */
  public closeOverlayMenu(): void {
    this.applicationVersionSelection = null;
    this.teammateMenuOpened = false;
    this.updateMainMenuItems();
  }

  /** Application comparison function to avoid unnecessary from scratch rendering. */
  public trackApplication(_: number, application: Application): string {
    return `${application.getIdentifier()}`;
  }
}
