import { Injectable, Injector } from '@angular/core';
import { AppIdentifier } from './app-identifier';
import { Application } from './application';


@Injectable({
  providedIn: 'root'
})
export class WelcomeApplication extends Application {
  public constructor(injector: Injector) {
    super(
      injector,
      {
        identifier: AppIdentifier.Welcome,
        title: 'Welcome',
        partOfMainMenu: false,
        fullscreen: true,
        loginRequired: false,
        loginForbidden: true,
      },
    );
  }
}
