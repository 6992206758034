import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Subject, takeUntil, of, delay } from 'rxjs';
import { Application, ApplicationState } from 'src/app/applications';

@Component({
  selector: 'hub-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class ApplicationComponent implements AfterViewInit, OnChanges, OnDestroy {
  public readonly ApplicationState = ApplicationState;

  /** The application to render. */
  @Input()
  public application: Application | null;

  private unsubscribeStateListener$: Subject<void>;
  private applicationState: ApplicationState;

  /** Binding to effectively hide the application whenever inactive. */
  @HostBinding('class.inactive')
  public get classInactive(): boolean {
    return this.applicationState == ApplicationState.Inactive;
  }

  /** Binding to prepare the application to be displayed whenever activating. */
  @HostBinding('class.activating')
  public get classActivating(): boolean {
    return this.applicationState == ApplicationState.Activating;
  }

  /** Binding to effectively show the application whenever active. */
  @HostBinding('class.active')
  public get classActive(): boolean {
    return this.applicationState == ApplicationState.Active;
  }

  /** Binding to prepare the application to be hidden whenever deactivating. */
  @HostBinding('class.deactivating')
  public get classDeactivating(): boolean {
    return this.applicationState == ApplicationState.Deactivating;
  }

  /** Binding to prepare the application to be hidden whenever deactivating. */
  @HostBinding('class.deactivated')
  private deactivatedState: boolean;
  private deactivatedStateCancellation$: Subject<void>;

  /**
   * <iframe> tag reference as a query.
   * A query is used and not direct reference as the <iframe> is sometimes not rendered at all.
   */
  @ViewChildren('iframe', { read: ElementRef })
  private iframeQuery: QueryList<ElementRef> | null;

  public constructor(private hostElement: ElementRef) {
    this.application = null;
    this.unsubscribeStateListener$ = new Subject<void>();
    this.applicationState = ApplicationState.Inactive;
    this.iframeQuery = null;

    this.deactivatedState = false;
    this.deactivatedStateCancellation$ = new Subject<void>();
  }

  public ngOnChanges(): void {
    if (this.application) {
      this.application.state$.pipe(takeUntil(this.unsubscribeStateListener$)).subscribe((state: ApplicationState): void => {
        if (this.applicationState == ApplicationState.Deactivating && state == ApplicationState.Inactive) {
          this.performDeactivationAnimation();
        }
        else {
          this.deactivatedStateCancellation$.next();
        }

        this.applicationState = state;
      });
    }
    else {
      this.unsubscribeStateListener$.next();

      if (this.applicationState == ApplicationState.Deactivating) {
        this.performDeactivationAnimation();
      }

      this.applicationState = ApplicationState.Inactive;
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribeStateListener$.next();
    this.unsubscribeStateListener$.complete();

    this.deactivatedStateCancellation$.next();
    this.deactivatedStateCancellation$.complete();
  }

  public ngAfterViewInit(): void {
    if (!this.iframeQuery) {
      // TODO: To be 100% safe, we should postpone this initialization.
      console.error(`Query not read. This should never happen.`);
      return;
    }

    if (this.iframeQuery.length > 0) {
      this.updateApplicationHandle(this.iframeQuery.first);
    }

    this.iframeQuery.changes.subscribe((query: QueryList<ElementRef>) => {
      this.updateApplicationHandle(query.length ? query.first : null);
    });
  }

  private performDeactivationAnimation(): void {
    if (!this.hostElement || !this.hostElement.nativeElement) {
      return;
    }

    // Get maximum transition duration
    const css: CSSStyleDeclaration = window.getComputedStyle(this.hostElement.nativeElement);
    const durations: number[] = css.transitionDuration.split(',').map((d: string): number => {
      const regex: RegExp = new RegExp('^(?<value>[0-9]*(?:\.[0-9]+)?)\s*(?<unit>[a-z]+)$');
      const units: Map<string, number> = new Map<string, number>([
        ['s', 1000],
        ['ms', 1],
      ]);

      const matches: RegExpMatchArray | null = d.trim().match(regex);

      if (!matches || !matches.groups || !units.has(matches.groups['unit'] || '')) {
        return 0;
      }

      return parseFloat(matches.groups['value'] || '0') * units.get(matches.groups['unit'])!;
    });

    const durationMs: number = Math.max(...durations);

    // Perform animation - keep deactivated state until the CSS maximum transition duration
    this.deactivatedState = true;
    of(0).pipe(
      takeUntil(this.deactivatedStateCancellation$),
      delay(durationMs),
    ).subscribe((_: number): void => {
      this.deactivatedState = false;
    });
  }

  /** Sends/removes the iframe reference to/from the application. */
  private updateApplicationHandle(element: ElementRef | null): void {
    if (!element || !element.nativeElement) {
      this.application?.setHandle(null);
    }
    else {
      const iframe: HTMLIFrameElement = element.nativeElement;
      this.application?.setHandle(iframe.contentWindow);
    }
  }
}
