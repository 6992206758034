import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Session, SessionOrganization } from '@heydayai/microapp-core';
import { Subject, takeUntil } from 'rxjs';
import { AppIdentifier } from 'src/app/applications/app-identifier';
import { AuthService } from 'src/app/auth';
import { SessionService } from 'src/app/session';

@Component({
  selector: 'hub-teammate-menu',
  templateUrl: './teammate-menu.component.html',
  styleUrls: ['./teammate-menu.component.scss']
})
export class TeammateMenuComponent implements OnDestroy {
  public session: Session;
  public userFullName: string;
  public userInitials: string;

  public userLanguage: string;
  public languages: Map<string, string>;

  public organizationId: string;
  public organizationName: string;
  public organizations: Map<string, string>;

  public isLoggingOut: boolean = false;

  private unsubscribe: Subject<void> = new Subject();

  @Output()
  public close: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public settingsApplicationClicked: EventEmitter<string> = new EventEmitter<string>();

  public constructor(private sessionService: SessionService, private authService: AuthService) {
    this.userFullName = '';
    this.userInitials = '';

    this.userLanguage = 'en';
    this.languages = new Map<string, string>([
      ['en', 'English'],
      ['fr', 'Français'],
      ['es', 'Español'],
    ]);

    this.organizationId = '';
    this.organizationName = '';
    this.organizations = new Map<string, string>();

    this.session = this.sessionService.getSession();
    this.updateDataFromSession();

    this.listenForSessionUpdates();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public logout(): boolean {
    this.isLoggingOut = true;
    this.authService.logout();
    this.close.emit();
    return false;
  }

  public onSettingsClicked(): boolean {
    this.settingsApplicationClicked.emit(AppIdentifier.Settings);
    return false;
  }

  public onLanguageChange(event: Event): void {
    this.sessionService.updateUiLanguage(this.userLanguage);
  }

  public onOrganizationChange(event: Event): void {
    this.sessionService.switchOrganization(this.organizationId);
  }

  private listenForSessionUpdates(): void {
    this.sessionService.session$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((session: Session): void => {
        this.session = session;
        this.updateDataFromSession();
    });
  }

  private updateDataFromSession(): void {
    if (!this.session.isLoggedIn()) {
      return;
    }

    this.userFullName = this.session.getUser()?.getFullName() ?? 'Hootsuite User';
    this.userInitials = this.session.getUser()?.getInitials() ?? 'HU';
    this.userLanguage = this.session.getLanguage();
    this.organizationId = this.session.getOrganization()?.getIdentifier();
    this.organizationName = this.session.getOrganization()?.getName();

    this.organizations.clear();
    this.session.getAvailableOrganizations().forEach((organization: SessionOrganization): void => {
      this.organizations.set(organization.getIdentifier(), organization.getName());
    });
  }
}
