import { AttributesOf, HttpGetRequest, HttpRequestPayload, HttpResponsePayload, ResponseParameter, UrlParameter } from '@heydayai/microapp-core';

export class UserPayload extends HttpRequestPayload<UserPayload> {
  @UrlParameter()
  userId!: string;
}

export class UserResponse extends HttpResponsePayload<UserResponse> {
  @ResponseParameter()
  metadata!: Metadata;

  @ResponseParameter()
  lastName!: string;

  @ResponseParameter()
  userId!: string;

  @ResponseParameter()
  globalRoles!: string[];

  @ResponseParameter()
  firstName!: string;

  @ResponseParameter()
  identities!: string[];
}

class Metadata {
  @ResponseParameter()
  comment!: string;

  @ResponseParameter()
  createdAt!: string;

  @ResponseParameter()
  updatedAt!: string;

  @ResponseParameter()
  version!: number;
}

export class GetUserRequestV1 extends HttpGetRequest<UserPayload, UserResponse> {
  constructor(data: AttributesOf<UserPayload>) {
    super(`users://v1/users/${data.userId}`, new UserPayload(data), UserResponse);
  }
}
