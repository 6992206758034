<header>
  <div>
    <span class="initials">{{ userInitials }}</span>
  </div>
  <h1>
    {{ userFullName }}
  </h1>
  <div>

    <a class="logout" href="#" (click)="logout()">
      <span
        *ngIf="!isLoggingOut"
        class="logout-text">
        Logout
      </span>
      <div class="logout-loading" *ngIf="isLoggingOut"></div>

    </a>
  </div>
</header>
<!-- <article>
  <section class="language">
    <label for="languageSelection">Language</label>
    <select id="languageSelection" [(ngModel)]="userLanguage" (ngModelChange)="onLanguageChange($event)">
      <option *ngFor="let language of languages | keyvalue" [ngValue]="language.key">{{ language.value }}</option>
    </select>
  </section>
</article> -->

<!-- Settings app is empty at the moment
<div class="bottom">
  <div class="settings">
    <a href="#" (click)="onSettingsClicked()">
      <hub-svg id="settings" src="/assets/icons/settings.svg" alt="Settings"></hub-svg>
    </a>
  </div>
</div> -->
