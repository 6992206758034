import { EnvironmentHubService } from './environment-hub.service';
import { AppIdentifier } from './app-identifier';
import { Injector } from '@angular/core';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BehaviorSubject, fromEvent, Observable, Subject, of, forkJoin } from 'rxjs';
import { delay, filter, finalize, map, take } from 'rxjs/operators';
import { ApplicationActivatedMessage, ReadyMessage, Message, UrlUpdateMessage, Session, SessionUpdatedMessage, RedirectMessage, LoginMessage, Token, AnalyticsEventOrigin, RequestApiCallMessage, ApiCallEndpoints, ResponseApiCallMessage, GenericApiResponse, OwnPostsQueries, CompetitorsPostsQueries, PostPerformancesQueries } from '@heydayai/microapp-core';
import { AuthService } from '../auth';
import { MessageHandler } from '../messaging/message-handler';
import { AnalyticsService } from '@heydayai/microapp-angular-core';
import { UserSelectedNavigationV1AnalyticsEvent } from '../analytics-events';
import { MessageQueue } from './message-queue';
import { ApiService } from '../api/api.service';

interface VersionEntry {
  /** URL of this application's version. */
  url: string;

  /** Version release's UTC timestamp. */
  timestamp: number;
}

interface VersionsIndex {
  /** Current live version. */
  live: string;

  /** Deployed versions list. */
  versions: { [key: string]: VersionEntry };
}

interface ApplicationConfig {
  identifier: AppIdentifier,
  title: string,
  persistent?: boolean,
  iconPath?: string,
  rootPath?: string,
  partOfMainMenu?: boolean,
  fullscreen?: boolean,
  loginRequired?: boolean,
  loginForbidden?: boolean,
}

export enum ApplicationState {
  Inactive = 0,
  Activating = 1,
  Active = 2,
  Deactivating = 3,
}

interface RedirectionContext {
  origin: AnalyticsEventOrigin;
  context: string;
}

export abstract class Application {
  private static redirectionContextStack: RedirectionContext[] = [];

  /** Unique application identifier. */
  private identifier: AppIdentifier;

  /** Path of the application's icon. */
  private iconPath: string;

  /** Title of the application. */
  private title: string;

  /** URL root path of the application. */
  private rootPath: string;

  /** Current operating state of the application. */
  private _state$: BehaviorSubject<ApplicationState>;

  /** Current operating state of the application. */
  public readonly state$: Observable<ApplicationState>;

  /**
   * States if the application has been fully loaded or not.
   * It can change over the time as an application can be unloaded.
   */
  private loaded$: BehaviorSubject<boolean>;

  /** States if the application is persistent, ie. is never unloaded. */
  private persistent: boolean;

  /** States if the application is accessible from the main menu or not. */
  private partOfMainMenu: boolean;

  /** States if the main menu is visible or not while being in this application. */
  private fullscreen: boolean;

  /** States if the user must be logged-in to access the application or not. */
  private loginRequired: boolean;

  /** States if the user must be logged-out to access the application or not. */
  private loginForbidden: boolean;

  /** Current live version of the application, the default one. */
  private liveVersion: string | null;

  /** Current active version of the application, the one being used. */
  private activeVersion: string;

  /** URL of the application's version index. */
  private versionsIndexUrl: URL;

  /** The versions of the application. */
  private versions: Map<string, SafeResourceUrl>;

  /** URL of the application's current active version. */
  private externalUrl: BehaviorSubject<SafeResourceUrl>;

  /** Window handle of the application for sending and receiving messages. */
  private handle: Window | null;

  /** Registered application message handlers. */
  private messageHandlers: Map<string, Function>;

  /** (Injected) Angular Sanitizer for generating Safe URLs. */
  private sanitizer: DomSanitizer;

  /** (Injected) Location service for manipulating the browser's current active URL. */
  private location: Location;

  /** (Injected) Authentication service for handling the login flow. */
  private authService: AuthService;

  /** (Injected) Analytics service for tracking users. */
  private analyticsService: AnalyticsService;

  /** (Injected) Authentication service for handling the login flow. */
  private apiService: ApiService;

  private messageHandler: MessageHandler<this>;

  private messageQueue: MessageQueue = new MessageQueue();

  public constructor(injector: Injector, config: ApplicationConfig) {

    const { identifier, title } = config;
    this.sanitizer = injector.get(DomSanitizer);
    this.location = injector.get(Location);
    this.authService = injector.get(AuthService);
    this.analyticsService = injector.get(AnalyticsService);
    this.apiService = injector.get(ApiService);

    this.identifier = identifier;
    this.iconPath = config?.iconPath ?? `/assets/icons/${identifier}.svg`;
    this.title = title;
    this.rootPath = config?.rootPath ?? `/${identifier}`;
    this._state$ = new BehaviorSubject<ApplicationState>(ApplicationState.Inactive);
    this.state$ = this._state$.asObservable();
    this.loaded$ = new BehaviorSubject<boolean>(false);
    this.persistent = config?.persistent ?? false;
    this.partOfMainMenu = config?.partOfMainMenu ?? true;
    this.fullscreen = config?.fullscreen ?? false;
    this.loginRequired = config?.loginRequired ?? true;
    this.loginForbidden = config?.loginForbidden ?? false;

    this.liveVersion = null;
    this.activeVersion = '';
    const indexVersionsURL = injector.get(EnvironmentHubService).getAppUrl(identifier);
    this.versionsIndexUrl = indexVersionsURL;
    this.versions = new Map<string, SafeResourceUrl>();
    this.externalUrl = new BehaviorSubject<SafeResourceUrl>(this.sanitizer.bypassSecurityTrustResourceUrl(''));
    this.loadVersionsIndex();

    this.handle = null;
    this.messageHandlers = new Map<string, Function>();

    this.messageHandler = new MessageHandler(this.identifier, this.handle, this);
    this.setupMessageHandlers();
  }

  /** Returns the unique identifier of the application. */
  public getIdentifier(): string {
    return this.identifier;
  }

  /** Returns the application's icon path. */
  public getIconPath(): string {
    return this.iconPath;
  }

  /** Returns the title of the application. */
  public getTitle(): string {
    return this.title;
  }

  /** Returns the URL root path of the application. */
  public getRootPath(): string {
    return this.rootPath;
  }

  public isPartOfMainMenu(): boolean {
    return this.partOfMainMenu;
  }

  public isFullscreen(): boolean {
    return this.fullscreen;
  }

  public isLoginRequired(): boolean {
    return this.loginRequired;
  }

  public isLoginForbidden(): boolean {
    return this.loginForbidden;
  }

  /** Loads asynchronously the application's versions. */
  private loadVersionsIndex(): Observable<void> {
    const done$: Subject<void> = new Subject<void>();

    fetch(
      this.versionsIndexUrl.toString(),
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
        cache: 'default'
      }
    ).then(async (response: Response): Promise<void> => {
      if (!response.ok) {
        throw new Error(`Unable to load "${this.identifier}" application versions.`);
      }

      try {
        const data: VersionsIndex = await response.json();

        if (!data) {
          // throw
        }

        this.versions.clear();

        // Sort the versions from the most recent to the oldest.
        Object.entries(data.versions || {}).sort((a: [string, VersionEntry], b: [string, VersionEntry]): number => {
          return (b[1]?.timestamp || 0) - (a[1]?.timestamp || 0);
        }).forEach((value: [string, VersionEntry]): void => {
          const [version, versionUrl] = value;
          const url = new URL(versionUrl.url, this.versionsIndexUrl.origin);
          this.registerVersion(version, url);
        });

        // TODO: if no version throw!

        this.liveVersion = this.versions.has(data.live) ? data.live : null;
        this.activeVersion = this.liveVersion || Array.from(this.versions.keys())[0];

        // Works but wrong output type:
        //   this.externalUrl = domSanitizer.sanitize(SecurityContext.URL, externalUrl.toString());
        // Doesn't work, but good type:
        //   this.externalUrl = domSanitizer.sanitize(SecurityContext.RESOURCE_URL, externalUrl.toString());

        this.externalUrl.next(this.versions.get(this.activeVersion)!);
      }
      catch (e) {
        throw new Error(`Unable to parse "${this.identifier}" application versions.`);
      }
      finally {
        done$.next();
        done$.complete();
      }
    });

    return done$.asObservable();
  }

  /** Registers or update the URL of the application's version. */
  public registerVersion(version: string, url: URL): void {
    this.versions.set(
      version,
      this.sanitizer.bypassSecurityTrustResourceUrl(url.toString())
    );
  }

  /** Returns the versions of the application. */
  public getVersions(): string[] {
    return Array.from(this.versions.keys());
  }

  /** Returns the current live version of the application, the default one. */
  public getLiveVersion(): string | null {
    return this.liveVersion;
  }

  /** Returns the current active version of the application, the one being used. */
  public getActiveVersion(): string {
    return this.activeVersion;
  }

  /** Set the current active version of the application, the one to use. */
  public setActiveVersion(version: string): void {
    if (!this.versions.has(version)) {
      throw new Error(`Version "${version}" doesn't exist for application "${this.identifier}".`);
    }

    this.activeVersion = version;
    this.externalUrl.next(this.versions.get(this.activeVersion)!);
  }

  /** Returns the URL of the application, which can change over time. */
  public getExternalUrl(): Observable<SafeResourceUrl> {
    return this.externalUrl.asObservable();
  }

  /** Activates the application. */
  public activate(url: string = '/'): void {
    this._state$.next(ApplicationState.Activating);

    this.onceLoaded().subscribe(() => {
      // TODO: restrict this a bit more...
      let applicationActivatedMessage = new ApplicationActivatedMessage(Session.get(), url);
      this.sendMessage(applicationActivatedMessage);

      this._state$.next(ApplicationState.Active);
      console.log(`Application "${this.getIdentifier()}" activated.`);
    });
  }

  /**
   * Deactivate the application upon completion of the returned subject.
   * @param notBeforeMs milliseconds to at least wait for before the deactivation.
   */
  public beginDeactivation(notBeforeMs: number = 0): Subject<void> {
    const completionCallback$: Subject<void> = new Subject<void>();
    const completionEvent$: Subject<void> = new Subject<void>();
    const notBefore$: Observable<void> = of(0).pipe(delay(notBeforeMs), map((_: number): void => {}));

    console.log(`Application "${this.getIdentifier()}" is being deactivated.`);
    this._state$.next(ApplicationState.Deactivating);

    completionCallback$.pipe(finalize((): void => {
      completionEvent$.next();
      completionEvent$.complete();
    })).subscribe();

    // Wait for both events before effectively deactivating.
    forkJoin([notBefore$, completionEvent$]).subscribe((): void => {
      this.deactivate();
    });

    return completionCallback$;
  }

  /** Deactivate the application. */
  public deactivate(): void {
    this._state$.next(ApplicationState.Inactive);
    console.log(`Application "${this.getIdentifier()}" deactivated.`);
  }

  /**
   * States if the application is currently fully loaded.
   * It can be active, but not yet fully loaded.
   */
  public isLoaded(): boolean {
    return this.loaded$.value;
  }

  /**
   * Resets the loading states of this application.
   */
  public resetLoadingState(): void {
    this.loaded$.next(false);
  }

  /**
   * Returns an Observable which resolve once the application is fully loaded.
   * It resolves immediately when already loaded.
   */
  public onceLoaded(): Observable<void> {
    return this.loaded$.asObservable().pipe(
      filter(((loaded: boolean): boolean => loaded)),
      map((_: boolean): void => { }),
      take(1)
    );
  }

  /** States if the application is currently active. */
  public isActive(): boolean {
    return [
      ApplicationState.Activating,
      ApplicationState.Active,
    ].includes(this._state$.value);
  }

  public isDeactivating(): boolean {
    return this._state$.value == ApplicationState.Deactivating;
  }

  /** States if the application is persistent - never unloaded. */
  public isPersistent(): boolean {
    return this.persistent;
  }

  /** Sets or unsets the Window handle of the application. */
  public setHandle(handle: Window | null) {
    this.handle = handle;

    if (!this.handle) {
      this.loaded$.next(false);
    }
    if (this.handle) {
      // start emptying message queue
      while (this.messageQueue.hasMessage()) {
        const msgToSend = this.messageQueue.popFront();
        if(!msgToSend) {
          return;
        }
        this.sendMessage(msgToSend);
      }
    }
  }

  public updateSession(session: Session): void {
    this.sendMessage(new SessionUpdatedMessage(session));
  }

  public sendMessage(message: Message): void {
    if (!this.loaded$.value) {
      console.log(`[application.ts][hub > ${this.identifier}] "${message.getType()}" message was not sent as the application is not ready yet.`);
      return;
    }

    message.setApplication(this.identifier);
    if(!this.handle) {
      this.messageQueue.add(message);
      return;
    }
    let messageToSend = message.toString();
    this.handle?.postMessage(messageToSend, { targetOrigin: '*' });
    console.log(`[application.ts][hub > ${this.identifier}]`, message);
  }

  /** Registers all incoming message handlers. */
  private setupMessageHandlers(): void {
    this.messageHandler.registerMessageHandler(ReadyMessage, this.processReadyMessage);
    this.messageHandler.registerMessageHandler(UrlUpdateMessage, this.processUrlUpdateMessage);
    this.messageHandler.registerMessageHandler(RedirectMessage, this.processRedirectMessage);
    this.messageHandler.registerMessageHandler(LoginMessage, this.processLoginMessage);
    this.messageHandler.registerMessageHandler(RequestApiCallMessage, this.processRequestApiCallMessage);

    // TODO: unsubscribe on death
    fromEvent(window, 'message').pipe(
      map((event: Event): Message | null => Message.fromEvent(event)),
    ).subscribe((message: Message | null): void => {
      // Ignore invalid message or whenever the application is not active

      if (!message || !message.isForApplication(this.identifier) || !this.isActive()) {
        return;
      }

      console.log(`[application.ts][${this.identifier} > hub]`, message);

      this.messageHandler.processMessage(message);
    });
  }

  /**
   * Handles the `Ready` message.
   * Marks the application as fully loaded.
   */
  private processReadyMessage(_: ReadyMessage): void {
    this.loaded$.next(true);
  }

  /**
   * Handles the `UrlUpdate` message.
   * Update the browser's location bar URL to reflect the application navigation.
   */
  private processUrlUpdateMessage(message: UrlUpdateMessage): void {
    const url: string = this.rootPath + message.getUrl();
    const pageId: string = message.getPageId() || this.analyticsService.getOriginForApplication(this.identifier, this.activeVersion, 'unknown').origin;
    let hubContext: RedirectionContext | null = Application.popRedirectionContext();

    let origin: AnalyticsEventOrigin;
    let redirectionContext: string;

    if (message.hasOrigin()) {
      origin = message.getOrigin()!;
      redirectionContext = message.getRedirectContext();  // TODO: There is typo in the lib, should be named getRedirectionContext()
    }
    else if (hubContext) {
      origin = hubContext.origin;
      redirectionContext = hubContext.context;
    }
    else {
      origin = this.analyticsService.getOriginForApplication(this.identifier, this.activeVersion, 'unknown');
      redirectionContext = 'unknown';
    }

    // Warning: possible concurrency issues
    // We clear the redirect origin stack to avoid concurrency issues, it's preferrable to not have the data
    // rather having a wrong one in the events which would be misleading on the user journey.
    Application.clearRedirectionContexts();

    this.analyticsService.track(
      UserSelectedNavigationV1AnalyticsEvent as any,
      {
        pageSourceRedirectionContext: redirectionContext,
        targetPageId: pageId,
      },
      origin,
      Session.get().getApiCallType()
    );

    this.location.go(url);
  }

  /**
   * Handle the `Redirect` message.
   * Redirect the browser to the communicated URL.
   */
  private processRedirectMessage(message: RedirectMessage): void {
    // TODO: Block UI or show loading screen?
    document.location.href = message.getUrl();
  }

  /**
   * Handle the `Login` message.
   * Register the communicated token in the session.
   */
  private processLoginMessage(message: LoginMessage): void {
    const token = Token.parse(message.getToken())
    const refreshToken = Token.parse(message.getRefreshToken());

    const currentSession = Session.get();
    currentSession.updateToken(token);
    currentSession.updateRefreshToken(refreshToken);

    this.authService.login(currentSession);
  }

  private processRequestApiCallMessage(message: RequestApiCallMessage): void {
    switch (message.getEndpoint()) {
      case ApiCallEndpoints.GetOwnPosts:
        const ownPostsQueries = message.getOptions() as OwnPostsQueries;
        this.apiService.getOwnPosts(ownPostsQueries.sortBy, ownPostsQueries.orderBy).subscribe((data: GenericApiResponse) => {
          this.sendResponseApiCallMessage(message.getEndpoint(), data);
        });
        break;
      case ApiCallEndpoints.GetCompetitorsPosts:
        const competitorsPostsQueries = message.getOptions() as CompetitorsPostsQueries;
        this.apiService.getCompetitorsPosts(competitorsPostsQueries.sortBy, competitorsPostsQueries.orderBy).subscribe((data: GenericApiResponse) => {
          this.sendResponseApiCallMessage(message.getEndpoint(), data);
        });
        break;
      case ApiCallEndpoints.GetPostRecommendations:
        this.apiService.getPostRecommendations().subscribe((data: GenericApiResponse) => {
          this.sendResponseApiCallMessage(message.getEndpoint(), data);
        });
        break;
      case ApiCallEndpoints.GetPostPerformances:
        const postPerformancesQueries = message.getOptions() as PostPerformancesQueries;
        this.apiService.getPostPerformances(postPerformancesQueries.createdAfter, postPerformancesQueries.createdBefore).subscribe((data: GenericApiResponse) => {
          this.sendResponseApiCallMessage(message.getEndpoint(), data);
        });
        break;

      default:
        break;
    }
  }

  private sendResponseApiCallMessage(endpoint: ApiCallEndpoints, data: GenericApiResponse): void {
    const responseApiCallMessage: ResponseApiCallMessage = new ResponseApiCallMessage(endpoint, data);
    this.sendMessage(responseApiCallMessage);
  }

  public static pushRedirectionContext(origin: AnalyticsEventOrigin, context?: string): void {
    Application.redirectionContextStack.push({
      origin: origin,
      context: !!context ? `${origin.origin}.${context}` : '',
    });
  }

  private static popRedirectionContext(): RedirectionContext | null {
    return (Application.redirectionContextStack.length && Application.redirectionContextStack.pop()) || null;
  }

  private static clearRedirectionContexts(): void {
    Application.redirectionContextStack = [];
  }
}
