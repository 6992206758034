import { HttpPostRequest } from "@heydayai/microapp-core";
import { RefreshPayload } from "./refresh-request-payload";
import { RefreshResponse } from "./refresh-response";

export class RefreshRequest extends HttpPostRequest<RefreshPayload, RefreshResponse> {
  constructor(refreshToken: string) {
    super('oidc://refresh', new RefreshPayload(), RefreshResponse);
    this.setHeader('Authorization', `Bearer ${refreshToken}`);
  }
}
