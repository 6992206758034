import { Injectable } from '@angular/core';
import { Session } from '@heydayai/microapp-core';
import { exhaustMap, filter, from, interval, map, Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { RefreshResponse, RefreshRequest } from './api';

/**
 * Service responsible for validating the current state of the token.
 * If we need to refresh the current token, the service makes the request and emits
 * the value to it's subscriber.
 */
@Injectable()
export class TokenWatcherService {
  private static readonly tokenValidationIntervalMs = 10000;
  private static readonly percentageLimitBeforeTokenRefresh = 0.5;
  public resfreshResponse$ = new Subject<RefreshResponse>();
  private isInitialized: boolean = false;

  private onDestroy$: Subject<void> = new Subject();

  /**
   * validates at a fixed interval if the token should be refreshed.
   * If it needs to be refreshed, saves and emits the new up to date token.
   */
    public setupTokenWatcher() {
      if(this.isInitialized) {
        return;
      };

      interval(TokenWatcherService.tokenValidationIntervalMs)
        .pipe(
          takeUntil(this.onDestroy$),
          filter(() => this.shouldRefreshSession()),
          exhaustMap(() => this.createTokenRefreshRequest()))
        .subscribe((res) => this.resfreshResponse$.next(res));

      this.isInitialized = true;
    }

  /**
   * Takes the current time and, compares the expiration of the current token (exp)
   * vs the time it was issued at (iat).
   * If we are at more than 50% of the duration of the token, we should be fetching a new
   * token.
   *
   * @returns whetever we should fetch a new token or not.
   */
  private shouldRefreshSession(): boolean {
    const token = Session.get().getToken();
    if (!token) {
      return true;
    }
    const exp = token?.exp;
    const iat = token?.iat;
    if (!exp || !iat) {
      return true;
    }

    const now = +new Date() / 1000;
    const diff = iat - exp;
    const diffVsNow = iat - now;
    const percentDiff = diffVsNow / diff;

    return percentDiff >= TokenWatcherService.percentageLimitBeforeTokenRefresh;
  }

    /**
   * Gets the refresh token and creates and observable from the request.
   * @returns
   */
    public createTokenRefreshRequest(): Observable<RefreshResponse> {
      const refToken = Session.get().getRefreshToken()?.getJwt() ?? '';
      const req = new RefreshRequest(refToken);
      return from(req.send())
        .pipe(map((res) => res.getData())
      );
    }
}
