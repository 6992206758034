import { Injectable, OnDestroy } from '@angular/core';
import { ApertureClient, HttpResponse, Session, SessionUser, Storage, Token } from '@heydayai/microapp-core';
import {
  BehaviorSubject, catchError, exhaustMap, filter, from, map, Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { UserResponse } from '../user/api/users';
import { SessionService } from '../session';
import { UserApiService } from '../user';
import { RefreshRequest, LogoutResponse, LogoutRequest, RefreshResponse } from './api';
import { TokenWatcherService } from './token-watcher.service';
import { CurrentUserPayload, CurrentUserRequestV1 } from '../user/api/authorizer';
import { AppIdentifier } from '../applications/app-identifier';
import { EnvironmentHubService } from '../applications/environment-hub.service';

interface UserPermissions {
  "permission": {
    "memberId": number,
    "featureCode": string,
    "kind": string,
    "value": 0 | 1
  }
}

@Injectable()
export class AuthService implements OnDestroy {
  private static readonly authStorageKey: string = 'heyday.auth';
  private static readonly authRefreshStorageKey: string = 'heyday.refresh';
  private storage: Storage;
  private token: BehaviorSubject<Token | null> = new BehaviorSubject<Token | null>(null);
  private refreshToken: Token | null = null;
  public readonly token$: Observable<Token | null> = this.token.asObservable();

  private isRefreshingToken: boolean = false;

  public onAuth$: Observable<boolean> = this.onAuthValidation();

  private onDestroy$: Subject<void> = new Subject();

  public constructor(
    private sessionService: SessionService,
    private userService: UserApiService,
    private tokenWatcher: TokenWatcherService,
    private environmentService: EnvironmentHubService,
  ) {
    this.storage = Storage.get();
    let token: Token | null = null;
    let refreshToken: Token | null = null;

    if(window.location.href.includes(`${AppIdentifier.Welcome}/callback`) && !this.sessionService.isUsingApertureBasedAuth()) {
      this.setupTokenWatcher();
      return;
    }

    try {
      let rawToken = Session.get().getToken()?.getJwt();
      if (!rawToken) {
        rawToken =
          this.storage.getItem<string>(AuthService.authStorageKey) ?? '';
      }
      token = Token.parse(rawToken);

      let rawRefreshToken = Session.get().getRefreshToken()?.getJwt();
      if (!rawRefreshToken) {
        rawRefreshToken =
          this.storage.getItem<string>(AuthService.authRefreshStorageKey) ?? '';
      }
      refreshToken = Token.parse(rawRefreshToken);

      this.sessionService.updateSessionToken(token, refreshToken);
    } catch (_) {
    } finally {
      this.token = new BehaviorSubject<Token | null>(token);
      this.refreshToken = refreshToken;
      this.saveToken();
      this.saveUser().subscribe();
    }

    this.token$ = this.token.asObservable();

    if(!this.sessionService.isUsingApertureBasedAuth()) {
      this.setupTokenWatcher();
    }
  }

  setupTokenWatcher(): void {
    this.tokenWatcher.resfreshResponse$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(x => this.onRefreshTokenResponse(x))
    this.tokenWatcher.setupTokenWatcher();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public isLoggedIn(): boolean {
    return !!this.token.value;
  }

  public getAuthToken(): Token {
    if (!this.token.value) {
      throw new Error(
        'Attempted to get authentication token while being logged off.'
      );
    }

    return this.token.value!;
  }

  /**
   * Applies current state of the token values to the storage and other services.
   */
  private saveToken(): void {
    this.sessionService.updateAuthToken(this.token.value);

    if (!!this.token.value) {
      this.storage.setItem<string>(
        AuthService.authStorageKey,
        this.token.value!.getJwt()
      );
    } else {
      this.storage.removeItem(AuthService.authStorageKey);
    }

    if (!!this.refreshToken) {
      this.storage.setItem<string>(
        AuthService.authRefreshStorageKey,
        this.refreshToken.getJwt()
      );
    } else {
      this.storage.removeItem(AuthService.authRefreshStorageKey);
    }
  }

  private saveUser(): Observable<boolean> {
    const done$: Subject<boolean> = new Subject<boolean>();

    const isNewApi = this.sessionService.isUsingApertureBasedAuth();
    // for the aperture request, we need to have the environment service ready otherwise, we won't have the domain url
    const getApertureUser$ = this.environmentService.onceLoaded()
      .pipe(switchMap(() => this.userService.getUserMe(this.environmentService.env.apertureDomainUrl)));

    getApertureUser$.pipe(
      switchMap((user: UserResponse) => this.hasEntitlements(user, isNewApi)
        .pipe(
          map(hasEntitlements => ({ hasEntitlements, user }))
        )
      )
    ).subscribe({
      next: (userInfo) => {
        const {hasEntitlements, user} = userInfo;
        if(!hasEntitlements) {
          console.log('[auth] User does not have entitlements. Logging out.');
          this.logout();
        }
        const sessionUser: SessionUser = new SessionUser(
          user.userId,
          user.firstName,
          user.lastName,
          user.globalRoles
        );

        this.sessionService.updateUser(sessionUser);
        console.log(`[auth] Logged in as ${user.firstName} ${user.lastName}.`);
        done$.next(true);
        done$.complete();
      },
      error: () => {
        console.error('[auth] Could not retrieve the user identifier.');
        done$.next(false);
        done$.complete();
      },
    });

    return done$.asObservable();
  }

  /**
   * Takes the current session, updates its token and then process the new session as a login
   * so that the other apps also get the updated tokens.
   * @param token
   * @param refreshToken
   */
  public onRefreshSession(token: Token, refreshToken: Token) {
    const currentSession = Session.get();
    currentSession.updateToken(token);
    currentSession.updateRefreshToken(refreshToken);

    this.login(currentSession);
  }

  public login(session: Session): Observable<boolean> {
    this.sessionService.updateSession(session);
    this.token.next(session.getToken());
    this.refreshToken = session.getRefreshToken();
    this.saveToken();
    return this.saveUser();
  }

  /**
   *
   * @returns An observable telling us that the token has been invalidated and removed
   */
  public logout(): void {
    this.storage.clear();
    const { apertureDomainUrl, name } = this.environmentService.env;
    window.location.href = `https://${apertureDomainUrl}/logout?redirect=/${name}`
  }

  // TODO extract all refresh related features in it's own service
  /**
   * validates the newly received token, updates the storage and current values.
   * @param data
   */
  private onRefreshTokenResponse(data: RefreshResponse) {
    const { token, refreshToken } = data;
    const tokenObj = Token.parse(token);
    const refreshObj = Token.parse(refreshToken);

    this.token.next(tokenObj);
    this.refreshToken = refreshObj;
    this.saveToken();

    this.sessionService.updateSessionToken(tokenObj, refreshObj);
  }

  /**
   * Creates an observable that, on session change, will validate that the current tokens are valid.
   * Will also try to refresh the current token. If all else fails, returns a of(false).
   * Can be used to act as a guard to access certain ressources that will require api fetching for ex.
   * @returns indicates wherever or not the latest session change resulted in an authenticated session
   */
  private onAuthValidation(): Observable<boolean> {
    return this.sessionService.session$.pipe(
      map(session => !!session.getToken() && !!session.getRefreshToken()),
      exhaustMap((hasTokens) => hasTokens
        ? this.validateCurrentTokens()
        : of(false))
    );
  }

  /**
   * tries a quick call with the  current token to validate if the token is still valid
   * if it's not, will try to refresh the token.
   * if the refreshes also fails, we catch the error and return a false, indicating the token is invalid
   * @returns are the tokens valid or not
   */
  private validateCurrentTokens(): Observable<boolean> {
    // TODO if aperture, then only check via the /me call
    const authTestCall = new CurrentUserRequestV1(new CurrentUserPayload());
    return from(authTestCall.send())
      .pipe(
        map(testCall => testCall.isSuccessful()),
        map((isCallSuccessful: boolean) => {
          if(!isCallSuccessful && !this.isRefreshingToken) {
            this.isRefreshingToken = true;
            return this.refreshRequest();
          }
          return of(true);
        }),
        switchMap(refreshTokensCall => refreshTokensCall),
        catchError(() => of(false)),
        map((tokenValidationResponse: boolean | HttpResponse<RefreshResponse>) => {
          this.isRefreshingToken = false;
          // if we have a refresh response, we have to treat it, otherwise, the token was already refreshed
          // so we continue. The only situation where we fail is if any of the chain throws, the we return false
          if (tokenValidationResponse instanceof HttpResponse<RefreshResponse>) {
            const data = tokenValidationResponse?.getData() ?? null;
            if(data) {
              this.onRefreshTokenResponse(data);
            }
          }
          return !!tokenValidationResponse;
        }));
  }

  private refreshRequest(): Observable<HttpResponse<RefreshResponse>> {
    const refToken = Session.get().getRefreshToken()?.getJwt() ?? '';
    const req = new RefreshRequest(refToken);
    return from(req.send());
  }

  /**
   * Tries to refresh session. On success, will update the session and return true.
   * On failure, will simply return false.
   * @returns the success/failure of refreshing the session
   */
  refreshSession(): Observable<boolean> {
    if (this.isRefreshingToken) {
       return of(false);
    }
    this.isRefreshingToken = true;
    const req = this.refreshRequest();
    return req.pipe(
      map(res => res.getData()),
      catchError(() => of(null)),
      map(refreshData => {
        if (!refreshData) {
          return false;
        }

        this.isRefreshingToken = false;
        const { token, refreshToken } = this.getTokensFromRefreshResponse(refreshData);
        this.onRefreshSession(token, refreshToken);
        return true;
      }),
      catchError(() => {
        this.isRefreshingToken = false;
        return of(false);
      }));
  }

  /**
   * Takes a response, extracts and parse the tokens from a RefreshResponse.
   * @param res RefreshResponse data
   * @returns parsed token and refreshToken
   */
    private getTokensFromRefreshResponse(res: RefreshResponse | null): { token: Token, refreshToken: Token} {
      if(!res?.token || !res.refreshToken) {
        throw new Error('[auth] missing token in response from refresh call');
      }
      const token = Token.parse(res?.token);
      const refreshToken = Token.parse(res.refreshToken);
      return { token, refreshToken };
    }

    private hasEntitlements(user: UserResponse, isNewApi: boolean): Observable<boolean> {
      // if we are not using the new api, we don't need to check for entitlements
      if(!isNewApi) {
        return of(true);
      }

      return from(ApertureClient.apertureJsonRequest<UserPermissions>(
        `/service/entitlement/permissions/${user.userId}/BIRDIE_ACCESS`,
        this.environmentService.env.apertureDomainUrl
      )).pipe(
        catchError(() => of({
          permission: {
            value: 0
          }
        })),
        map(permissions => permissions.permission.value === 1)
      );
    }
}
