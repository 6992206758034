import { Message } from '@heydayai/microapp-core';

export class MessageQueue {
  private queue: Message[] = [];

  public add(msg: Message): void {
    this.queue.push(msg);
  }

  /**
   * Removes the first element (front of the queue) and shifts the array
   * @returns the first message of the queue
   */
  public popFront(): Message | null {
    const firstMsg = this.queue.shift() ?? null;

    return firstMsg;
  }

  public hasMessage(): boolean  {
    return !!this.queue.length;
  }
}
