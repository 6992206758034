<ul>
  <ng-container *ngFor="let version of versions">
    <li *ngIf="version != 'custom'" [class.live]="version == liveVersion" [class.active]="version == activeVersion">
      <a href="#" (click)="onVersionClicked(version)">
        {{ version }}
      </a>
    </li>
  </ng-container>
  <li *ngIf="customVersionsAllowed" class="custom" [class.active]="activeVersion == 'custom'">
    <ng-container *ngIf="!editingCustomVersion">
      <a href="#" (click)="onCustomVersionClicked()">
        Custom
      </a>
    </ng-container>
    <ng-container *ngIf="editingCustomVersion">
      <input type="text" [(ngModel)]="customVersion" placeholder="Version URL">
      <a href="#" (click)="useCustomVersion()">
        Use version
      </a>
      <a href="#" (click)="revertCustomVersion()">
        Cancel
      </a>
    </ng-container>
  </li>
</ul>
