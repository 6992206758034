import { AttributesOf, HttpGetRequest, HttpRequestPayload, HttpResponsePayload, ResponseParameter } from '@heydayai/microapp-core';

export class CurrentUserPayload extends HttpRequestPayload<CurrentUserPayload> {}

export class CurrentUserResponse extends HttpResponsePayload<CurrentUserResponse> {
  @ResponseParameter()
  userId!: string;
}

export class CurrentUserRequestV1 extends HttpGetRequest<CurrentUserPayload, CurrentUserResponse> {
  constructor(data: AttributesOf<CurrentUserPayload>) {
    super('authorizer://v1/currentUser', new CurrentUserPayload(data), CurrentUserResponse);
  }
}
