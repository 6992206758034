import { NgModule } from '@angular/core';
import { SessionModule } from '../session';
import { UserModule } from '../user';
import { AuthService } from './auth.service';
import { TokenWatcherService } from './token-watcher.service';

@NgModule({
  providers: [
    AuthService,
    TokenWatcherService,
  ],
  imports: [
    SessionModule,
    UserModule,
  ],
})
export class AuthModule {
}
