import { Injectable, OnDestroy } from '@angular/core';
import { ApiCallType, Session, SessionUser, Token, Storage } from '@heydayai/microapp-core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {
  private token: Token | null = Session.get().getToken();
  private refreshToken: Token | null = Session.get().getRefreshToken();
  private session: BehaviorSubject<Session>;
  public session$: Observable<Session>;
  private storage: Storage;

  private onDestroy: Subject<void> = new Subject();

  public constructor(
  ) {
    this.session = new BehaviorSubject<Session>(Session.get());
    this.session$ = this.session.asObservable();
    this.storage = Storage.get();
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  updateSessionToken(token: Token, refreshToken: Token) {
    const currentSession = Session.get();
    currentSession.updateToken(token);
    currentSession.updateRefreshToken(refreshToken);

    this.updateSession(currentSession);
  }

  updateSession(session: Session): void {
    Session.set(session);
    this.session.next(session);
    this.token = session.getToken();
    this.refreshToken = session.getRefreshToken();
  }

  public getSession(): Session {
    return this.session.value;
  }

  public getAuthToken(): string {
    return this.token?.getJwt() ?? '';
  }

  public getRefreshToken(): string {
    return this.refreshToken?.getJwt() ?? '';
  }

  public updateAuthToken(token: Token | null): void {
    this.token = token;
    const session: Session = this.session.value;
    session.updateToken(token);
    this.updateSession(session);
  }

  public updateUser(user: SessionUser): void {
    const session: Session = this.session.value;
    session.setUser(user);
    this.updateSession(session);
  }

  public updateUiLanguage(language: string): void {
    const session: Session = this.session.value;
    session.setLanguage(language);
    this.updateSession(session);
  }

  public switchOrganization(organizationId: string): void {
    const session: Session = this.session.value;
    session.setOrganization(organizationId);
    this.updateSession(session);
  }

  /**
   * Updates the api call type to use for the session.
   * @param apiCallType The type of api call to use. Either 'fromHub' or 'fromAperture'.
   */
  public updateApiCallType(apiCallType: ApiCallType): void {
    const session: Session = this.session.value;
    session.setApiCallType(apiCallType);

    // If the api call type is from the hub, we need to remove the token from the session.
    if(apiCallType === ApiCallType.FromHub) {
      this.updateAuthToken(null);
      this.storage.removeItem('heyday.auth');
    }
    this.updateSession(session);
  }

  /**
   * Determines if the session is using aperture based authentication.
   * Aperture based authentication uses cookies and therefore does not need to send the token in the header.
   * @returns {boolean} true if the session is using aperture based authentication, false otherwise.
   */
  public isUsingApertureBasedAuth(): boolean {
    // we are now always using the aperture auth
    return true;
  }
}
