<div id="page-container">
  <hub-main-menu
    *ngIf="mainMenuVisible"
    [items]="mainMenuItems"
    (itemClicked)="switchApplication($event)"
    (itemContextMenu)="enableVersionSelection($event)"
    [teammateMenuOpened]="teammateMenuOpened"
    (teammateMenuClicked)="openTeammateMenu()"
  ></hub-main-menu>
  <hub-application-versions
    *ngIf="applicationVersionSelection"
    [versions]="applicationVersionSelection.getVersions()"
    [liveVersion]="applicationVersionSelection.getLiveVersion()"
    [activeVersion]="applicationVersionSelection.getActiveVersion()"
    (versionClicked)="switchApplication(applicationVersionSelection.getIdentifier(), $event)"
  ></hub-application-versions>
  <hub-teammate-menu
    *ngIf="teammateMenuOpened"
    (close)="closeOverlayMenu()"
    (settingsApplicationClicked)="switchApplication($event)"
  ></hub-teammate-menu>
  <div id="application-container">
    <div
      *ngIf="applicationVersionSelection || teammateMenuOpened"
      id="application-disabled-overlay"
      (click)="closeOverlayMenu()"
    ></div>
    <hub-application
      *ngFor="let app of applications; trackBy: trackApplication"
      [application]="app"
    ></hub-application>
    <div
      *ngIf="(noApplicationLoaded$ | async) || noApplicationLoadedFadeOut"
      class="loading-overlay"
      [class.visible]="noApplicationLoaded$ | async"
      [class.fade-out]="noApplicationLoadedFadeOut"
    >
      <hub-svg src="/assets/icons/hootsuite.svg" alt="Hootsuite logo"></hub-svg>
  </div>
</div>
