import { Injectable } from '@angular/core';
import { CompetitorsPostsApertureService, OwnPostsApertureService, PostPerformancesApertureService, PostRecommendationsApertureService } from '@heydayai/microapp-angular-core';
import { CompetitorsSocialPostsSortBy, GenericApiResponse, OwnSocialPostsSortBy, PostsOrderBy } from '@heydayai/microapp-core';
import { catchError, map, Observable, of } from 'rxjs';


@Injectable()
export class ApiService {
  constructor(
    private competitorsPostsApertureService: CompetitorsPostsApertureService,
    private ownPostsApertureService: OwnPostsApertureService,
    private postPerformancesApertureService: PostPerformancesApertureService,
    private postRecommendationsApertureService: PostRecommendationsApertureService,
  ) { }

  public getOwnPosts(sortBy: OwnSocialPostsSortBy, orderBy: PostsOrderBy): Observable<GenericApiResponse> {
    return this.ownPostsApertureService.getOwnPosts(sortBy, orderBy).pipe(
      map((response) => ({ status: 200, statusText: 'OK', data: response.getRawData() })),
      catchError((err: any) => of<GenericApiResponse>({
        status: err.status,
        statusText: err.message,
        data: err.stack,
        error: true,
      }))
    );
  }

  public getCompetitorsPosts(sortBy: CompetitorsSocialPostsSortBy, orderBy: PostsOrderBy): Observable<GenericApiResponse> {
    return this.competitorsPostsApertureService.getCompetitorsPosts(sortBy, orderBy).pipe(
      map((response) => ({ status: 200, statusText: 'OK', data: response.getRawData() })),
      catchError((err: any) => of<GenericApiResponse>({
        status: err.status,
        statusText: err.message,
        data: err.stack,
        error: true,
      }))
    );
  }

  public getPostRecommendations(): Observable<GenericApiResponse> {
    return this.postRecommendationsApertureService.getPostRecommendations().pipe(
      map((response) => ({ status: 200, statusText: 'OK', data: response.getRawData() })),
      catchError((err: any) => of<GenericApiResponse>({
        status: err.status,
        statusText: err.message,
        data: err.stack,
        error: true,
      }))
    );
  }

  public getPostPerformances(createdAfter: string, createdBefore: string): Observable<GenericApiResponse> {
    return this.postPerformancesApertureService.getPostPerformances(createdAfter, createdBefore).pipe(
      map((response) => ({ status: 200, statusText: 'OK', data: response.getRawData() })),
      catchError((err: any) => of<GenericApiResponse>({
        status: err.status,
        statusText: err.message,
        data: err.stack,
        error: true,
      }))
    );
  }
}
