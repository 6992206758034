import { Injectable, Injector } from '@angular/core';
import { AppIdentifier } from './app-identifier';
import { Application } from './application';


@Injectable({
  providedIn: 'root'
})
export class HomeApplication extends Application {
  public constructor(injector: Injector) {
    super(
      injector,
      {
        identifier: AppIdentifier.Home,
        title: 'Home', //TODO fetch translation for title
        persistent: true
      },
    );
  }
}
