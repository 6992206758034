import { Injectable } from '@angular/core';
import { ApertureClient, HttpResponse } from '@heydayai/microapp-core';
import { from, map, Observable, switchMap } from 'rxjs';
import { CurrentUserPayload, CurrentUserRequestV1, CurrentUserResponse } from './api/authorizer';
import { GetUserRequestV1, UserPayload, UserResponse } from './api/users';

export interface MeResponse {
  id: string,
  email?: string,
  isActive?: boolean,
  createdDate?: string,
  modifiedDate?: string,
  fullName: string,
  companyName?: string,
  bio?: string,
  timezone?: string,
  language?: string;
}
export interface MeResponseData {
  data: MeResponse;
}

@Injectable()
export class UserApiService {
  private static readonly DEFAULT_API_DOMAIN = 'staging-birdie.hootsuite.com';

  private getCurrentUser(): Observable<HttpResponse<CurrentUserResponse>> {
    const payload = new CurrentUserPayload();
    const req = new CurrentUserRequestV1(payload);
   return from(req.send());
  }

  private getUserInfo(userId: string): Observable<HttpResponse<UserResponse>> {
    const payload = new UserPayload({userId})
    const req = new GetUserRequestV1(payload);
    return from(req.send());
  }

  /**
   * depending on the current auth flows, will get the information in a different way
   * @returns
   */
  public getUser(): Observable<UserResponse> {
    return this.getCurrentUser().pipe(
      map((currentUserResponse: HttpResponse<CurrentUserResponse>) => currentUserResponse.getData()),
      switchMap((currentUser: CurrentUserResponse) => {
      const userId = currentUser.userId ?? '';
      return this.getUserInfo(userId).pipe(map((userResponse: HttpResponse<UserResponse>) => userResponse.getData()));
    }));
  }

  public getUserMe(domain: string = UserApiService.DEFAULT_API_DOMAIN): Observable<UserResponse> {
    // TODO change to a custom procotol and use the user aperture service from microapp-angular-core
    // The reason we are using the ApertureClient directly is that this function is called before the initialization of the custom protocols
    return from(ApertureClient.apertureJsonRequest<MeResponseData>('/v1/me', domain))
      .pipe(map(res => this.mapMeResponseToUserResponse(res)));
  }

  private mapMeResponseToUserResponse(res: MeResponseData): UserResponse {
    // hacky way to create a first name and last name prop
    const meRes = res.data;
    const firstName = meRes.fullName.substring(0, meRes.fullName.indexOf(' '));
    const lastName = meRes.fullName.substring(meRes.fullName.indexOf(' ')).trim();

    return {
      lastName,
      userId: meRes.id,
      globalRoles: [],
      firstName,
      identities: [],
      __responseParameters: undefined,
      getParameters: undefined,
      metadata: {
        comment: '',
        createdAt: '',
        updatedAt: '',
        version: 1
      }
    } as unknown as UserResponse;
  }
}
