<ul>
  <li *ngFor="let item of items; trackBy: trackMainMenuItem" class="item" [class.active]="item.active && !teammateMenuOpened">
    <a href="#" (click)="onMenuItemClicked(item.identifier)" (contextmenu)="onMenuItemContextMenu(item.identifier)">
      <hub-svg *ngIf="!item.active" id="icon" [src]="item.iconPath" [alt]="item.title"></hub-svg>
      <hub-svg *ngIf="item.active" id="icon" src="/assets/icons/{{ item.identifier }}-active.svg" [alt]="item.title"></hub-svg>
      <span class="title">{{ item.title }}</span>
    </a>
  </li>
</ul>
<div class="bottom">
  <div class="item" [class.active]="teammateMenuOpened">
    <a href="#" (click)="onTeammateMenuClicked()">
      <span class="initials">{{ userInitials }}</span>
    </a>
  </div>
</div>
