import { Injectable, Injector } from '@angular/core';
import { Application } from './application';
import { AppIdentifier } from './app-identifier';

@Injectable({
  providedIn: 'root'
})
export class PostsApplication extends Application {
  public constructor(injector: Injector) {
    super(
      injector,
      {
        identifier: AppIdentifier.Posts,
        title: 'Your posts', //TODO fetch translation for title
      }
    );
  }
}
