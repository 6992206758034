import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Session } from '@heydayai/microapp-core';
import { EnvironmentHubService } from 'src/app/applications';
import { SessionService } from 'src/app/session';
import { MainMenuItem } from './main-menu-item';
import { switchMap, from, map } from 'rxjs';

@Component({
  selector: 'hub-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  /** Menu items to render. */
  @Input()
  public items: MainMenuItem[];

  /** States if the teammate menu is currently opened or not. */
  @Input()
  public teammateMenuOpened: boolean;

  /** Event triggered when an item has been clicked. Item unique identifier is sent. */
  @Output()
  public itemClicked: EventEmitter<string>;

  /** Event triggered when an item has been right-clicked. Item unique identifier is sent. */
  @Output()
  public itemContextMenu: EventEmitter<string>;

  /** Event triggered when the teammate menu icon has been clicked. */
  @Output()
  public teammateMenuClicked: EventEmitter<void>;

  /** Logged-in user initials */
  public userInitials: string;

  /* Can the logged-in user select app version. */
  private canSelectVersions: boolean;

  public constructor(
    private environmentService: EnvironmentHubService,
    private sessionService: SessionService,
  ) {
    this.items = [];
    this.teammateMenuOpened = false;
    this.itemClicked = new EventEmitter<string>();
    this.itemContextMenu = new EventEmitter<string>();
    this.teammateMenuClicked = new EventEmitter<void>();
    this.userInitials = '';
    this.canSelectVersions = false;

    this.listenForSessionUpdates();
  }

  private listenForSessionUpdates(): void {
    // TODO: unsubscribe
    this.sessionService.session$.pipe(
      switchMap((session) => from(session.isLoggedIn())
        .pipe(
          map(isLoggedIn => ({ session , isLoggedIn }))
        )
      )
    ).subscribe((res): void => {
      const { session, isLoggedIn } = res;
      if (isLoggedIn) {
        this.userInitials = session.getUser()?.getInitials() || 'HU';
      }

      // TODO this should be handled by the session and a proper entitlement check
      // what it was before : this.canSelectVersions = session.hasGlobalAdminRights();
      this.canSelectVersions = this.environmentService.canAccessVersion();
    });
  }

  /** Triggers the `itemClicked` event. */
  public onMenuItemClicked(itemId: string): boolean {
    this.itemClicked.emit(itemId);

    // Always returns false to prevent the browser from continuing the navigation.
    return false;
  }

  /** Triggers the `itemContextMenu` event. */
  public onMenuItemContextMenu(itemId: string): boolean {
    if (!this.canSelectVersions) {
      // Displays the browser context menu.
      return true;
    }

    this.itemContextMenu.emit(itemId);

    // Always returns false to prevent the browser from displaying the context menu.
    return false;
  }

  /** MainMenuItem comparison function to avoid unnecessary rendering. */
  public trackMainMenuItem(_: number, item: MainMenuItem): string {
    return `${item.identifier}`;
  }

  /** Triggers the `teammateMenuClicked` event. */
  public onTeammateMenuClicked(): boolean {
    this.teammateMenuClicked.emit();
    return false;
  }

  /** Does nothing except preventing the browser from doing its default actions. */
  public doNothing(): boolean {
    return false;
  }
}
