import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EnvironmentHubService } from 'src/app/applications';

@Component({
  selector: 'hub-application-versions',
  templateUrl: './application-versions.component.html',
  styleUrls: ['./application-versions.component.scss']
})
export class ApplicationVersionsComponent {
  /** Versions list to render. */
  @Input()
  public versions: string[];

  /** Current live version, the one by default. */
  @Input()
  public liveVersion: string | null;

  /** Current active version, the one being served at the moment. */
  @Input()
  public activeVersion: string | null;

  /** State if custom versions are allowed or not. */
  public customVersionsAllowed: boolean;

  /** Current custom version URL, stringified. */
  public customVersion: string | null;

  /** Previous custom version URL, stringified for revert purpose. */
  private previousCustomVersion: string | null;

  /** State if the custom version is being edited or not. */
  public editingCustomVersion: boolean;

  /** Event triggered when a version has been clicked. */
  @Output()
  public versionClicked: EventEmitter<string>;


  public constructor(private environmentService: EnvironmentHubService) {
    this.versions = [];
    this.liveVersion = null;
    this.activeVersion = null;
    this.customVersionsAllowed = this.environmentService.env.allowCustomApps;
    this.customVersion = "https://localhost:4200";
    this.previousCustomVersion = this.customVersion;
    this.editingCustomVersion = false;
    this.versionClicked = new EventEmitter<string>();
  }

  /** Triggers the `versionClicked` event. */
  public onVersionClicked(itemId: string): boolean {
    this.versionClicked.emit(itemId);

    // Always returns false to prevent the browser from continuing the navigation.
    return false;
  }

  /** Enable the custom version edition. */
  public onCustomVersionClicked(): boolean {
    this.previousCustomVersion = this.customVersion;
    this.editingCustomVersion = true;

    // Always returns false to prevent the browser from continuing the navigation.
    return false;
  }

  /** Revert any changes made to the custom version. */
  public revertCustomVersion(): boolean {
    this.customVersion = this.previousCustomVersion;
    this.editingCustomVersion = false;

    // Always returns false to prevent the browser from continuing the navigation.
    return false;
  }

  /** Save and use the custom version. */
  public useCustomVersion(): boolean {
    if (this.customVersion) {
      try {
        new URL(this.customVersion);
        this.editingCustomVersion = false;
        this.onVersionClicked(this.customVersion);
      }
      catch (e: any) {
        console.error(`Invalid custom version "${this.customVersion}": ${e.message}`);
      }
    }

    // Always returns false to prevent the browser from continuing the navigation.
    return false;
  }
}
