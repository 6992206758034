import { Location } from '@angular/common';
import { Injectable, Injector } from '@angular/core';
import { ApiCallType, Session } from '@heydayai/microapp-core';
import { BehaviorSubject, Observable, from, map, switchMap } from 'rxjs';
import { SessionService } from '../session';
import { Application } from './application';
import { BookmarksApplication } from './bookmarks-application';
import { PostsApplication } from './posts-application';
import { HomeApplication } from './home-application';
import { WelcomeApplication } from './welcome-application';
import { CompetitorsApplication } from './competitors-application';
import { SettingsApplication } from './settings-application';
import { AppIdentifier } from './app-identifier';
import { AnalyticsService } from '@heydayai/microapp-angular-core';


@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  /** Available applications list, indexed by application identifier. */
  private applications: Map<string, Application>;

  /** Internal behavior subject to notify about applications changes. */
  private _applications$: BehaviorSubject<Application[]>;

  /** Public observable to notificat about applications changes. */
  public applications$: Observable<Application[]>;

  /** Reference to the currently active application. */
  private activeApplication: Application;

  public constructor(
    private injector: Injector,
    private location: Location,
    private sessionService: SessionService,
    private analyticsService: AnalyticsService,
  ) {
    this.applications = new Map<string, Application>([
      this.createApplication(WelcomeApplication),
      this.createApplication(HomeApplication),
      this.createApplication(PostsApplication),
      this.createApplication(CompetitorsApplication),
      this.createApplication(BookmarksApplication),
      this.createApplication(SettingsApplication),
    ]);

    Application.pushRedirectionContext(this.analyticsService.getOrigin('main_page'), 'initial_loading');

    // Default application is taken from the current URL, or is the first defined application.
    this.activeApplication = this.getApplicationFromUrl(new URL(this.location.path(), document.location.href)) || this.getDefaultApplication();
    this.activeApplication.activate(this.getCurrentApplicationInnerUrl());

    this._applications$ = new BehaviorSubject<Application[]>([]);
    this.publishApplicationsChanges();

    this.applications$ = this._applications$.asObservable();

    // TODO: unsubscribe
    // whenever user plays with history (back, forward)
    this.location.onUrlChange((url: string) => {
      const application: Application | null = this.getApplicationFromUrl(new URL(url, document.location.href)) || this.getDefaultApplication();

      if (!application || application.isActive()) {
        return;
      }

      this.switchApplication('browser_action', application.getIdentifier());
    });

    // TODO: unsubscribe
    // TODO with the old auth flow (token based), this part of the code doesnt get triggered
    // for some reason when refreshing the page/navigating, which makes the app hang and wait
    // new token or send request without token (which fails). If we want to go back to this flow
    // this is pretty much the only thing that is broken, the rest should work as is.
    this.sessionService.session$.pipe(
      switchMap((session) => from(session.isLoggedIn()).pipe(
        map(isLoggedIn => ({ session , isLoggedIn }))
      ))
    ).subscribe((res): void => {
      const { session, isLoggedIn } = res;
      // we don't want to load any other app than the login app if we have a callback call
      if(window.location.href.includes(`${AppIdentifier.Welcome}/callback`) && !this.sessionService.isUsingApertureBasedAuth()) {
        this.switchApplication('login_callback', AppIdentifier.Welcome);
        return;
      }

      this.activeApplication.updateSession(session);


      if (!isLoggedIn && this.activeApplication.isLoginRequired()) {
        // TODO: Capture and store current route?
        // This is triggered whenever the user clicks the logout button.
        if(this.sessionService.isUsingApertureBasedAuth()) {
          this.location.go('/welcome');
        }
        this.switchApplication('session_expired', AppIdentifier.Welcome);
        return;
      }

      if (isLoggedIn && this.activeApplication.isLoginForbidden()) {
        this.switchApplication('initial_loading', this.getDefaultApplication().getIdentifier());
      }
    });
  }

  /** Notifies the listeners about applications changes. */
  private publishApplicationsChanges(): void {
    this._applications$.next(Array.from(this.applications.values()));
  }

  /** Switches the active application to the one specified, in its default version or in the specifed version if any. */
  public switchApplication(context: string, applicationIdentifier: string, version: string | null = null): void {
    const application: Application | undefined = this.applications.get(applicationIdentifier);

    // Ignore when the application doesn't exist or if the same version of the application is already active
    if (!application || (application == this.activeApplication && (!version || this.activeApplication.getActiveVersion() == version))) {
      return;
    }

    // Switch between two different applications (smooth transition)
    if (application != this.activeApplication) {
      const deactivatePreviousApplication$ = this.activeApplication.beginDeactivation(300);

      application.onceLoaded().subscribe((): void => {
        deactivatePreviousApplication$.complete();
      });
    }

    this.activeApplication.resetLoadingState();

    Application.pushRedirectionContext(this.analyticsService.getOrigin('main_page'), context);

    if (version) {
      application.setActiveVersion(version);
    }

    application.activate();
    this.activeApplication = application;

    this.publishApplicationsChanges();
  }

  /** Returns the default application identifier. */
  private getDefaultApplication(): Application {
    return Array.from(this.applications.values())
      .filter((app: Application): boolean => { return app.isLoginRequired(); })[0];
  }

  /** Returns the application instance whose rootPath matches the specified URL. */
  private getApplicationFromUrl(url: URL): Application | null {
    let application: Application | null = null;

    this.applications.forEach((app: Application) => {
      if (url.pathname.startsWith(app.getRootPath())) {
        application = app;
      }
    });

    return application;
  }

  /** Returns the current URL relative to the active application scope. */
  private getCurrentApplicationInnerUrl(): string {
    const url: URL = new URL(this.location.path(true), document.location.href);
    return [
      '/' + url.pathname.split('/').filter((_: string, index: number): boolean => { return index > 1; }).join('/'),
      url.search,
      url.hash,
    ].join('');
  }

  public reloadCurrentApp(): void {
    // TODO make something cleaner than a full blown reload
    window.location.reload();
  }

  /** Creates an application entry from the application class name. */
  private createApplication<T extends Application>(type: { new(_: Injector): T }): [string, T] {
    const application: T = this.injector.get(type);
    return [application.getIdentifier(), application];
  }
}
