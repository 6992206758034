import { Injectable } from '@angular/core';
import { LoggedOutMessage, Message, RequestSessionRefreshMessage } from '@heydayai/microapp-core';
import { fromEvent, map } from 'rxjs';
import { ApplicationsService } from '../applications';
import { AppIdentifier } from '../applications/app-identifier';
import { AuthService } from '../auth';
import { MessageHandler } from './message-handler';


@Injectable()
export class HubMessagingService {
  private isInitialized: boolean = false;
  private messageHandler: MessageHandler<this>;

  constructor(
    private applicationService: ApplicationsService,
    private authService: AuthService,
  ) {
    this.messageHandler = new MessageHandler(AppIdentifier.Hub, window, this);
  }

  /**
   * Setups the various listeners for the hub.
   */
  public listenForHubMessage(): void {
    if (this.isInitialized) {
      return;
    }

    this.messageHandler.registerMessageHandler(LoggedOutMessage, this.logout);
    this.messageHandler.registerMessageHandler(RequestSessionRefreshMessage, this.onRequestSessionRefreshMessage);

    fromEvent(window, 'message').pipe(
      map((event: Event): Message | null => Message.fromEvent(event)),
    ).subscribe((message: Message | null): void => {
      // Ignore invalid message or whenever the application is not active
      if (!message || !message.isForApplication(AppIdentifier.Hub)) {
        return;
      }

      this.messageHandler.processMessage(message);
    });

    this.isInitialized = true;
  }

  /**
   * request the auth service to invalidate current token.
   * Then,
   */
  private logout(): void {
    // TODO remove once the RequestSessionRefreshMessage is being sent from the other apps
    this.authService.logout();
    this.applicationService.switchApplication('logout', AppIdentifier.Welcome);
  }

  /**
   * Tries to get a new token by using the current refreshToken.
   * On success, ask the authService to handle the newest tokens and reloads the app.
   * On a fail, will logout the current user.
   *
   */
  private onRequestSessionRefreshMessage() {
    this.authService.refreshSession().subscribe(hasRefreshed => {
      if(!hasRefreshed) {
        this.logout();
      } else {
        this.applicationService.reloadCurrentApp();
      }
    })
  }
}
