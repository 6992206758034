import { ApplicationRef, DoBootstrap, enableProdMode, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpModule, CrashReporterModule, CrashReporterService, PackageJsonService, ApertureModule } from '@heydayai/microapp-angular-core';
import { ApiCallType, HttpCustomProtocols, Session, Storage } from '@heydayai/microapp-core';
import { AppComponent } from './app.component';
import { ApplicationsModule, EnvironmentHubService } from './applications';
import { AuthModule, AuthService, GlobalRoles } from './auth';
import { ApplicationComponent } from './components/application';
import { ApplicationVersionsComponent } from './components/application-versions';
import { MainMenuComponent } from './components/main-menu';
import { SvgComponent } from './components/svg';
import { TeammateMenuComponent } from './components/teammate-menu';
import { SessionService, TokenHttpInterceptor } from './session';

import packageInfo from '../../package.json';
import { HubMessagingService } from './messaging/hub-messaging.service';
import { catchError, from, map, of, switchMap, take } from 'rxjs';
import { UserModule } from './user';
import { ApiModule } from './api/api.module';

@NgModule({
  declarations: [
    AppComponent,
    SvgComponent,
    MainMenuComponent,
    ApplicationComponent,
    ApplicationVersionsComponent,
    TeammateMenuComponent,
  ],
  imports: [
    CrashReporterModule,
    ApplicationsModule,
    BrowserModule,
    AuthModule,
    FormsModule,
    HttpModule,
    UserModule,
    ApiModule,
    ApertureModule,
  ],
  providers: [
    { provide: PackageJsonService, useValue: new PackageJsonService('birdie', packageInfo) },
    { provide: HTTP_INTERCEPTORS, useClass: TokenHttpInterceptor, multi: true },
    HubMessagingService,
    EnvironmentHubService,
  ]
})
export class AppModule implements DoBootstrap {
  /** Sentry DSN */
  private static readonly crashReporterRemoteUrl: string = 'https://0e763bbd9736478bada39f3d57920aea@o3805.ingest.sentry.io/4504686020853760';

  constructor(
    private packageJson: PackageJsonService,
    private environmentService: EnvironmentHubService,
    private crashReporter: CrashReporterService,
    private sessionService: SessionService,
    private authService: AuthService,
  ) { }

  ngDoBootstrap(appRef: ApplicationRef): void {
    this.environmentService.onceLoaded().subscribe({
      next: () => {
        // Setup CrashReporter
        if (this.environmentService.env.productionModeEnabled) {
          enableProdMode();

          this.crashReporter.initialize({
            remoteUrl: AppModule.crashReporterRemoteUrl,
            environmentName: this.environmentService.env.name,
            applicationName: `birdie-${this.packageJson.name}`,
            applicationVersion: this.packageJson.version,
          });

          this.sessionService.session$.pipe(
            switchMap((session) => from(session.isLoggedIn()).pipe(map(isLoggedIn => ({ session , isLoggedIn }))))
          ).subscribe((res): void => {
            const { session, isLoggedIn } = res;
            const userId: string | null = isLoggedIn ? session.getUser().getIdentifier() : null;
            const hootsuiteUser: boolean = isLoggedIn && !session.getUser().isPartOf(GlobalRoles.BirdieUser);

            this.crashReporter.setUser(userId, hootsuiteUser);
          });
        }

        // Inject environment stylesheets
        this.environmentService.env.stylesheets.forEach((stylesheet: URL): void => {
          const tag: HTMLLinkElement = document.createElement('link');
          tag.rel = 'stylesheet';
          tag.type = 'text/css';
          tag.href = stylesheet.toString();
          document.head.appendChild(tag);
        });

        // Sets if API calls should be done from the Hub or native
        let apiCallType: ApiCallType = this.environmentService.env.apiCallType;
        this.sessionService.updateApiCallType(apiCallType);

        HttpCustomProtocols.register('authorizer', this.environmentService.env.authorizerUrl);
        HttpCustomProtocols.register('users', this.environmentService.env.usersUrl);
        HttpCustomProtocols.register('oidc', this.environmentService.env.oidcUrl);
        HttpCustomProtocols.register('analytics', this.environmentService.env.analyticsUrl);
        HttpCustomProtocols.register('aperture', this.environmentService.env.apertureApiUrl);

        this.authService.onAuth$.pipe(catchError(() => of(false)), take(1)).subscribe(x => {
          appRef.bootstrap(AppComponent);
        });
      },
      error: (err): void => {
        throw new Error(err);
      }
    });
  }
}
