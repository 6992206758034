import { NgModule } from '@angular/core';
import { ApertureModule, CompetitorsPostsApertureService, OwnPostsApertureService, PostPerformancesApertureService, PostRecommendationsApertureService } from '@heydayai/microapp-angular-core';
import { ApiService } from './api.service';

@NgModule({
  imports: [
    ApertureModule,
  ],
  providers: [
    ApiService,
    CompetitorsPostsApertureService,
    OwnPostsApertureService,
    PostPerformancesApertureService,
    PostRecommendationsApertureService,
  ]
})
export class ApiModule { }
