import { AckMessage, Message } from '@heydayai/microapp-core';
import { AppIdentifier } from '../applications/app-identifier';

export class MessageHandler<T> {
  /** Registered application message handlers. */
  private messageHandlers: Map<string, Function> = new Map<string, Function>();

  constructor(
    private identifier: AppIdentifier,
    /** Window handle of the application for sending and receiving messages. */
    private handle: Window | null,
    private emitterThis: T
  ) {}

  /** Registers a communication message handler. */
  public registerMessageHandler<T extends Message>(type: { getMessageType(): string }, handler: (message: T) => void): void {
    this.messageHandlers.set(type.getMessageType(), handler);
  }

    /**
   * Processes an incoming message.
   * Automatically sends an acknowledgement and forwards the message to corresponding message handler.
   */
    public processMessage(message: Message): void {
      if (message.getType() == AckMessage.getMessageType()) {
        return;
      }

      // TODO: restrict this a bit more...
      this.sendMessage(message.createAckMessage());

      if (this.messageHandlers.has(message.getType())) {
        this.messageHandlers.get(message.getType())!.bind(this.emitterThis)(message);
      }
    }

    public sendMessage(message: Message): void {
      message.setApplication(this.identifier);
      let messageToSend = message.toString();
      this.handle?.postMessage(messageToSend, { targetOrigin: '*' });
    }
}
