import { Injectable, Injector } from '@angular/core';
import { AppIdentifier } from './app-identifier';
import { Application } from './application';


@Injectable()
export class SettingsApplication extends Application {
  public constructor(injector: Injector) {
    super(
      injector,
      {
        identifier: AppIdentifier.Settings,
        title: 'Settings', //TODO fetch translation for title
        persistent: true,
        partOfMainMenu: false,
      },
    );
  }
}
