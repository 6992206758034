import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiCallType } from '@heydayai/microapp-core';
import { Observable } from 'rxjs';
import { SessionService } from './session.service';

@Injectable()
export class TokenHttpInterceptor implements HttpInterceptor {
  public constructor(private sessionService: SessionService) {}
  private omitCalls = ['refresh', 'logout'];
  private skipInterceptor = false;

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.sessionService.isUsingApertureBasedAuth()) {
      return next.handle(req);
    }

    this.omitCalls.forEach(route => {
      if(req.url.includes(route)){
        this.skipInterceptor = true;
      }
    })

    const token: string | null = this.sessionService.getAuthToken();

    if (token && !this.skipInterceptor) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`,
      }
      });
    }

    return next.handle(req);
  }
}
