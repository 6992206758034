import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, Subject, take } from 'rxjs';

@Component({
  selector: 'hub-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements AfterViewInit, OnChanges {
  /** Source URL of the SVG file. */
  @Input()
  public src: string;

  /** Alternative text. */
  @Input()
  public alt: string;

  /** Override of the SVG default viewBox. */
  @Input()
  public viewBox: string | null;

  /** <svg> tag reference, for dynamic content insertion. */
  @ViewChild('svgContainer')
  private svgContainer: ElementRef | null;

  /** Original SVG viewbox. */
  private svgViewbox: string;

  /** Original SVG children nodes, ie. svg inner content. */
  private svgChildNodes: Node[];

  /** States the rendering task is technically possible, ie. Angular view has loaded. */
  private renderingPossible: boolean;

  /** States a new rendering pass is needed to reflect the latest changes. */
  private renderingNeeded: boolean;

  constructor(private hostRef: ElementRef) {
    this.src = '';
    this.alt = '';
    this.viewBox = null;
    this.svgContainer = null;
    this.svgViewbox = "0 0 0 0";
    this.svgChildNodes = [];
    this.renderingPossible = false;
    this.renderingNeeded = false;
  }

  public ngAfterViewInit(): void {
    this.renderingPossible = true;
    this.renderSvg();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['src'] && changes['src'].previousValue != changes['src'].currentValue) {
      SvgComponent.loadSvg(this.src).pipe(take(1)).subscribe((svg: SVGSVGElement) => {
        if (!svg.hasAttribute('viewBox')) {
          console.warn(`Missing viewBox attribute in SVG data: "${this.src}".`);
        }
        else {
          this.svgViewbox = svg.getAttribute('viewBox')!;
        }

        this.setSvgChildNodes(svg.childNodes);
      });
    }
  }

  /** Returns the active viewBox. */
  public getViewBox(): string {
    return this.viewBox || this.svgViewbox;
  }

  /** Renders the latest state of the SVG, when required and possible. */
  private renderSvg(): void {
    if (!this.renderingNeeded || !this.renderingPossible || !this.svgContainer || !this.svgContainer.nativeElement) {
      return;
    }

    this.renderingNeeded = false;
    this.svgContainer.nativeElement.replaceChildren(...this.svgChildNodes);
  }

  /** Replaces the SVG content with the provided nodes and triggers the rendering. */
  private setSvgChildNodes(nodes: NodeListOf<ChildNode>): void {
    this.svgChildNodes = [];

    nodes.forEach((node: ChildNode): void => {
      this.svgChildNodes.push(node);
    });

    this.renderingNeeded = true;
    this.renderSvg();
  }

  /** Asynchronously loads a SVG file from an URL. */
  private static loadSvg(src: string): Observable<SVGSVGElement> {
    const data$: Subject<SVGSVGElement> = new Subject<SVGSVGElement>();
    const xhr = new XMLHttpRequest();

    xhr.onload = function () {
      try {
        if (!this.responseXML) {
          throw new Error(`Empty file: "${src}".`);
        }

        const svg: SVGSVGElement | null = this.responseXML.querySelector('svg');

        if (!svg) {
          throw new Error(`Invalid SVG file: "${src}"`);
        }

        data$.next(svg);
      }
      catch (e) {
        throw new Error(`Incorrect SVG data from "${src}".`)
      }
      finally {
        data$.complete();
      }
    };

    xhr.onerror = function () {
      data$.complete();
      throw new Error(`Unable to load SVG "${src}".`);
    }

    xhr.open('GET', src);
    xhr.send();

    return data$.asObservable();
  }
}
