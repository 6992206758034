import { Injectable, Injector } from '@angular/core';
import { AppIdentifier } from './app-identifier';
import { Application } from './application';


@Injectable({
  providedIn: 'root'
})
export class BookmarksApplication extends Application {
  public constructor(injector: Injector) {
    super(
      injector,
      {
        identifier: AppIdentifier.Bookmarks,
        title: 'Library', //TODO fetch translation for title
        persistent: true,
         partOfMainMenu: false // TODO: Not part of the M0.5 release, will be activated later
      },
    );
  }
}
