import { AppIdentifier } from './app-identifier';
import { Environment, EnvironmentService, EnvironmentVariable } from '@heydayai/microapp-angular-core';
import { Injectable } from '@angular/core';
import { ApiCallType } from '@heydayai/microapp-core';

type AppsUrls<T> = Omit<Record<AppIdentifier, T>, 'hub'>;
class HubEnvironment extends Environment {
  @EnvironmentVariable({
    parser: (data: string[]): URL[] => {
      return (data || []).map((url: string): URL => new URL(url));
    },
  })
  public stylesheets!: URL[];

  @EnvironmentVariable()
  public allowCustomApps!: boolean;

  @EnvironmentVariable()
  public allowVersionsChange!: boolean;

  @EnvironmentVariable()
  public apiCallType!: ApiCallType;

  @EnvironmentVariable({
    parser: (data: AppsUrls<string>): AppsUrls<URL> => {
      return {
        welcome: new URL(data.welcome),
        home: new URL(data.home),
        posts: new URL(data.posts),
        bookmarks: new URL(data.bookmarks),
        competitors: new URL(data.competitors),
        settings: new URL(data.settings)
      };
    },
  })
  public apps!: AppsUrls<URL>;

  @EnvironmentVariable()
  public authorizerUrl!: string;

  @EnvironmentVariable()
  public oidcUrl!: string;

  @EnvironmentVariable()
  public usersUrl!: string;

  @EnvironmentVariable()
  public analyticsUrl!: string;

  @EnvironmentVariable()
  public apertureDomainUrl!: string;

  @EnvironmentVariable()
  public apertureApiUrl!: string;
}


@Injectable()
export class EnvironmentHubService extends EnvironmentService<HubEnvironment> {
  constructor() {
    super(HubEnvironment);
  }

  public getAppUrl(appType: AppIdentifier): URL {
    return this.env.apps[appType as keyof AppsUrls<URL>];
  }

  // TODO this check should be handled by a specific user entitlement that doesnt not exist yet
  public canAccessVersion(): boolean {
    return this.env.allowVersionsChange;
  }
}
